<script setup lang="ts">
import { EventTeaser, Term } from '~/api/drupal'
import { useScrollToTargetOnChange } from '~/composables/useScrollToTargetOnChange'
const props = defineProps<{
  text: {
    title: string
    location: string
    noResults: string
  }
  options: {
    type?: Term[]
    location: { value: string; label: string }[]
  }
  filter: {
    type?: string[]
    location: string[]
  }
  years: Record<number, EventTeaser[]>
  page: number
  pages: number
  noResults: boolean
  showFilter?: boolean
}>()

const emit = defineEmits(['update:active', 'update:page'])

const filter = computed({
  get: () => props.filter,
  set: (value) => emit('update:active', value),
})
const page = computed({
  get: () => props.page,
  set: (value) => emit('update:page', value),
})

const { scrollTarget } = useScrollToTargetOnChange(page)
</script>

<template>
  <SectionSpecial :title="text.title">
    <div v-if="showFilter" ref="scrollTarget" class="filters">
      <Tags v-if="filter.type && options.type" v-model:active="filter.type" :tags="options.type" multiple />
      <h4 v-animate="{ animation: 'vertical-reveal' }" class="location">{{ text.location }}</h4>
      <Checkbox
        v-model="filter.location"
        v-animate="{ animation: 'vertical-reveal' }"
        class="location-options"
        :options="options.location"
        :required="false"
      />
    </div>
    <p v-if="noResults" v-animate="{ animation: 'vertical-reveal' }" class="no-results">
      {{ text.noResults }}
    </p>
    <div v-for="(items, year) in years" :key="year" :class="['year', { 'no-filters': !showFilter }]">
      <h3 class="year-title">{{ year }}</h3>
      <LinkListLarge>
        <template #default="{ isHovered }">
          <LinkListLargeItemEvent
            v-for="item in items"
            :key="item.id"
            v-animate="{ animation: 'vertical-reveal' }"
            :date="item.date"
            :title="item.title"
            :location="item.location"
            :link="item.link"
            :dim="isHovered"
          />
        </template>
      </LinkListLarge>
    </div>

    <Pagination v-if="pages > 1" v-model:page="page" class="tl-11 tl-o-1" :max="pages" />
  </SectionSpecial>
</template>
<style scoped lang="scss">
.filters {
  @media all and (min-width: get-breakpoint(t)) and (max-width: get-breakpoint(tl)) {
    margin-top: 48px;
  }
}
.location:not(:first-child) {
  @include fluid(margin-top, 30px, 40px);
}
:deep(.location-options .formkit-options) {
  flex-direction: row;
  gap: 25px;
}

.no-results {
  @include fluid(margin-top, 32px, 48px);
}

.year {
  @include fluid(margin-top, 32px, 48px);
  &.no-filters:first-child {
    margin: 0;
  }
}

.year-title {
  margin-bottom: 0;
}
.pagination {
  margin-top: 48px;
}
</style>
